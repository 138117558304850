import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Components/Home';
import Nav from './Components/Nav';
import ManageTeachers from './Components/ManageTeachers';
import ManagePosts from './Components/ManagePosts';
import ManageBulletin from './Components/ManageBulletin';
import ManageTV from './Components/ManageTV';
import { Box } from '@mui/material';
function App() {
  return (
    <div className="App" style={{ "margin": "0px" }}>
      <BrowserRouter>
        <Box sx={{ flexGrow: 1 }}>
          <Nav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/manageteachers" element={<ManageTeachers />} />
            <Route path="/manageposts" element={<ManagePosts />} />
            <Route path="/managebulletin" element={<ManageBulletin />} />
            <Route path="/managetv" element={<ManageTV />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </div>
  );
}

export default App;
