import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PeopleIcon from '@mui/icons-material/People';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import LiveTvIcon from '@mui/icons-material/LiveTv';
const Nav = () => {
    return (
        <AppBar position="static" className="Nav" sx={{ display: "flex", flexDirection: 'row' }} color='error'>
            <Link to="/" style={{ textDecoration: "none" }}>
                <Typography variant="h6" noWrap sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, fontWeight: 700, color: 'black', textDecoration: 'none', }}>
                    <HelpOutlineIcon sx={{ height: "100%" }} />
                    <b>How to Use</b>
                </Typography>
            </Link>
            <Link to="/manageposts" style={{ textDecoration: "none" }}>
                <Typography variant="h6" noWrap sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, fontWeight: 700, color: 'black', textDecoration: 'none', }}>
                    <QuestionAnswerIcon sx={{ height: "100%" }} />
                    Manage Posts
                </Typography></Link>
            <Link to="/manageteachers" style={{ textDecoration: "none" }}>
                <Typography variant="h6" noWrap sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, fontWeight: 700, color: 'black', textDecoration: 'none', }}>
                    <PeopleIcon sx={{ height: "100%" }} />
                    Manage Teachers
                </Typography>
            </Link>
            <Link to="/managebulletin" style={{ textDecoration: "none" }}>
                <Typography variant="h6" noWrap sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, fontWeight: 700, color: 'black', textDecoration: 'none', }}>
                    <PresentToAllIcon sx={{ height: "100%" }} />
                    Manage Bulletin
                </Typography>
            </Link>
            <Link to="/managetv" style={{ textDecoration: "none" }}>
                <Typography variant="h6" noWrap sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, fontWeight: 700, color: 'black', textDecoration: 'none', }}>
                    <LiveTvIcon sx={{ height: "100%" }} />
                    Manage TV
                </Typography>
            </Link>
        </AppBar>
    )
}

export default Nav